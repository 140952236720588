.Footer-container{
position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    padding: 1rem 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f{
    justify-content: center;
    display: flex;
    align-items: center;
}
.logo-f>img{
    width: 10rem;
}